export const useCompareStore = defineStore('compare', () => {
  const selectedEntity = ref<'vacancies' | 'companies' | null>(null) // vacancies or companies
  const selectedCompareItems = ref([])
  const MAXCOMPARE = 3

  function handleChange(
    id: number,
    value: boolean,
    entity?: 'vacancies' | 'companies' | null,
  ) {
    if (entity) selectedEntity.value = entity
    if (value) {
      selectedCompareItems.value.push(id)
    } else {
      selectedCompareItems.value = selectedCompareItems.value.filter(
        (itemId) => itemId !== id,
      )
      if (selectedCompareItems.value.length === 0) selectedEntity.value = null
    }
  }

  async function handleCompare() {
    // set professional default to 'companies' compare view
    if (selectedEntity.value === null) {
      selectedEntity.value = 'companies'
    }
    await navigateTo({
      path: `/recommendations/${selectedEntity.value}/compare`,
      query: {
        ids: selectedCompareItems.value,
      },
    })
  }

  function clearSelectedItems() {
    selectedCompareItems.value = []
    selectedEntity.value = null
  }

  return {
    handleChange,
    selectedCompareItems,
    handleCompare,
    selectedEntity,
    clearSelectedItems,
    MAXCOMPARE,
  }
})
